import Order from './order';
import Turn from './turn';
import ItemLine from './item-line';
import printManager from '../print-manager';
import localAgent from '@/plugins/local-agent.js'
const TaxesHooks = {};

TaxesHooks.calculatedFromTaxInclude = (tax, price) => {
	const base = (tax.base * price / 100);
	//console.log(base, tax.percentage)
	return base - (base / (1 + (parseFloat(tax.percentage) / 100)));
}


TaxesHooks.calculated = (tax, price) => {
	//console.log(tax)
	const base = (tax.base * price / 100);
	return base * ((parseFloat(tax.percentage) / 100));
}


class PosManager {
	constructor(db, store, http, filters) {
		this.db = db;
		this.filters = filters;
		this.store = store;
		this.http = http;
		this.priceLists = null;
		this.createOrLoadOrder();
		this.taxesManager = TaxesHooks;
		//this.loadPriceLists();

	}
	async createOrder(validateTurn, defaults) {
		this.currentOrder = await this.createOrderWithDefaults(validateTurn, defaults);
		this.notifyChangeInOrder();
	}
	async createOrLoadOrder() {
		this.currentOrder = await this.getLocalCurrentOrder();
		//console.log(this.currentOrder)
		if (!this.currentOrder) {
			this.currentOrder = this.createOrderWithDefaults();
		}
		this.notifyChangeInOrder();
	}
	async createOrderWithDefaults(validateTurn, defaults) {
		if (typeof validateTurn == 'undefined') {
			validateTurn = true;
		}
		if (!defaults) {
			defaults = {};
		}
		let order = new Order();
		// la hora se coloca al momento de guardar la orden por primera vez, ya que puede pasar mucho tiempo hasta que se registre una nueva orden
		//order.setDateOrder(this.filters.datetime((new Date())));
		const user = this.store.getters.user;
		const installation = this.store.getters.installation;
		//console.log(installation)
		//order.setBusPersonCashierId(user.id);
		//order.setDataCashier(user);

		order.setBusSubsidiaryId(installation.subsidiary_id);
		order.setIsElectronicInvoice(false);
		order.setPriceTip(0);
		order.setIsDomicile(false);
		order.setIsOnTable(false);
		order.setNameTable(null);
		order.setStorePickup(true);
		order.setPriceDomicile(0);
		order.setDomiciliary(null);
		order.setSeller(null);
		order.setInvoiceId(null);
		order.setCustomer(null);
		order.setCoupon(null);
		order.setActiveTip(false);
		order.setInvoiceWasGenerated(false);

		if (validateTurn) {
			const turn = this.store.getters.turn;
			if (!turn) {
				alert('No existe un turno abierto');
				throw new Error('No existe un turno abierto');
			}
			if (turn && turn.id) {
				order.setBillCashRegisterTurnId(turn.id);
				order.setBillCashRegisterId(installation.cash_id);
				order.setTurnLocalReference(turn.local_reference);
			}

		}
		//console.log('turn...')
		//console.log(turn)
		console.log('new order........', order.local_reference)
		//console.log(order)
		return order;
	}

	saveLocalCurrentOrder() {
		console.log('Updating current order.....')
		localStorage.setItem('currentOrder', JSON.stringify(this.currentOrder))
	}
	getLocalCurrentOrder() {
		let item = localStorage.getItem('currentOrder');
		if (item) {
			try {
				let data = JSON.parse(item);
				return Order.create(data);
			} catch (e) {
				console.error(e)
				return null;
			}
		}
	}
	notifyChangeInOrder() {
		this.saveLocalCurrentOrder();
		this.store.commit('orderChanged');
	}

	async syncTurn(turn) {
		return await this.db.saveTurn(turn, this.http);
	}


	createReference() {
		return '_' + (new Date()).getTime();
	}
	async loadPriceLists() {
		this.priceLists = await this.db.getPriceLists();
		console.log(this.priceLists)
	}

	async getTurnInfo(turnId) {
		let response = await this.http.get('api/v1/pos/info-turn/' + turnId, {}, true);
		return response.turn;
	}

	async getOpenedOrders(params) {
		let installation = this.store.getters.installation;
		// como la orden se va a pagar, puede obtenerla desde cualquier caja
		//cash_register: installation.cash_id
		let response = await this.http.get('api/v1/orders-pending', {}, true);
		return response.items;
	}

	async getClosedOrders(params) {
		let installation = this.store.getters.installation;
		let response = await this.http.get('api/v1/orders-approved', { cash_register: installation.cash_id, cash_register_turn: this.store.getters.turn.id }, true);
		return response.items;
	}

	async getTotalOpenedOrders(params) {
		let installation = this.store.getters.installation;
		//cash_register: installation.cash_id
		let response = await this.http.get('api/v1/orders-pending-total', {}, true);
		return response.total;
	}

	async getOpenedOrdersToReceive(params) {
		//return await this.db.getOpenedOrders(params);
		let installation = this.store.getters.installation;
		//cash_register: installation.cash_id,
		let response = await this.http.get('api/v1/orders-pending', { order_status: "pending", }, true);
		return response.items;
	}

	async getOrders(params) {
		return await this.db.getOrders(params);
	}

	getDataUser(user) {
		return { id: user.id, name: user.name }
	}

	async saveOrderSeller(order, http, showMsgServer) {
		const installation = this.store.getters.installation;
		const user = this.store.getters.user;
		if (!order.bus_subsidiary_id) {
			order.bus_subsidiary_id = installation.subsidiary_id;
		}

		if (!order.bus_person_seller_id) {
			order.bus_person_seller_id = user.id;
		}
		if (!order.data_seller) {
			order.data_seller = this.getDataUser(user);
		}
		order.from_seller = true;
		/*if (!order.bill_cash_register_id) {
			order.bill_cash_register_id = installation.cash_id;
		}*/

		order.date_order = this.cleanDateOrder();

		if (!order.opening_date) {
			order.opening_date = this.cleanDateOrder();
		}
		let response = await this.db.saveOrderRemote(order, this.http, showMsgServer);

		return response;
	}

	cleanDateOrder(date) {
		if (date) {
			if (typeof date == 'number') {
				return this.filters.datetime(new Date(date));
			} else {
				return this.filters.datetime(date);

			}
		} else {
			return this.filters.currentDatetime();
		}
	}

	async saveOrder(order, http, showMsgServer) {
		const installation = this.store.getters.installation;
		const user = this.store.getters.user;
		const turn = this.store.getters.turn;
		if (!order.bus_subsidiary_id) {
			order.bus_subsidiary_id = installation.subsidiary_id;
		}
		if (!order.bill_cash_register_turn_id) {
			//let turn = await this.db.getTurn(order.turn_local_reference);
			order.bill_cash_register_turn_id = turn.id;
		}

		if (!order.bus_person_cashier_id) {
			order.bus_person_cashier_id = user.id;
		}
		if (!order.data_cashier) {
			order.data_cashier = this.getDataUser(user);;
		}
		if (!order.bill_cash_register_id) {
			order.bill_cash_register_id = installation.cash_id;
		}


		order.date_order = this.cleanDateOrder();

		if (!order.opening_date) {
			order.opening_date = this.cleanDateOrder();
		}
		// se comenta ya que solo se va a usar el pos online
		//let response = await this.db.saveOrder(order, this.http, showMsgServer);
		let response = await this.db.saveOrderRemote(order, this.http, showMsgServer);


		// se comenta ya que solo se va a usar el pos online
		/*if(appUtils.isAppPos() ){
			setTimeout(() => {
				let txt = this.db.serialize();
				localAgent.save(txt)
			}, 500)
		}*/

		return response;
	}

	addPayment(order, payment) {
		order.addPayment(payment);
		this.notifyChangeInOrder();
	}
	async closeTurn(turn) {

		if (turn.company && turn.company.id) {
			turn.company = turn.company.id
		}
		if (turn.subsidiary && turn.subsidiary.id) {
			turn.subsidiary = turn.subsidiary.id
		}
		if (turn.cash_register && turn.cash_register.id) {
			turn.cash_register = turn.cash_register.id
		}

		let response = await this.http.post('api/v1/cash-turn/' + turn.id, { ...turn }, true, true);
		return response.success;
		/*return new Promise((resolve, reject) => {
			try {
				this.syncTurn(turn);
				resolve(true);
			} catch (error) {
				console.log('er', error);
				reject(error);
			}
		});*/
	}

	assignConsecutive(order) {
		order.assignConsecutive();
		this.notifyChangeInOrder();
	}

	setCoupon(order, value) {
		order.setCoupon(value);
		this.notifyChangeInOrder();
	}

	setClosingDate(order, value) {
		order.setClosingDate(value);
		this.notifyChangeInOrder();
	}

	setNote(order, value) {
		order.setNote(value);
		this.notifyChangeInOrder();
	}

	setIsDomicile(order, value) {
		order.setIsDomicile(value);
		this.notifyChangeInOrder();
	}

	setIsOpen(order, value) {
		order.setIsOpen(value);
		this.notifyChangeInOrder();
	}

	setInvoiceId(order, value) {
		order.setInvoiceId(value);
		this.notifyChangeInOrder();
	}

	setIsElectronicInvoice(order, value) {
		order.setIsElectronicInvoice(value);
		this.notifyChangeInOrder();
	}

	setIsOnTable(order, value) {
		order.setIsOnTable(value);
		this.notifyChangeInOrder();
	}

	setActiveTip(order, value) {
		order.setActiveTip(value);
		this.notifyChangeInOrder();
	}


	setNameTable(order, table) {
		order.setNameTable(table);
		this.notifyChangeInOrder();
	}

	setOrderStatus(order, value) {
		order.setOrderStatus(value);
		this.notifyChangeInOrder();
	}

	setPriceDomicile(order, value) {
		order.setPriceDomicile(value);
		this.notifyChangeInOrder();
	}

	setPriceTip(order, value) {
		order.setPriceTip(value);
		this.notifyChangeInOrder();
	}

	setDataTaxes(order, value) {
		order.setDataTaxes(value);
		this.notifyChangeInOrder();
	}

	setTotalTaxes(order, value) {
		order.setTotalTaxes(value);
		this.notifyChangeInOrder();
	}

	setTotalBase(order, value) {
		order.setTotalBase(value);
		this.notifyChangeInOrder();
	}

	setSubtotal(order, value) {
		order.setSubtotal(value);
		this.notifyChangeInOrder();
	}

	setTotalDiscounts(order, value) {
		order.setTotalDiscounts(value);
		this.notifyChangeInOrder();
	}

	setStorePickup(order, value) {
		order.setStorePickup(value);
		this.notifyChangeInOrder();
	}

	round(num) {
		let m = Number((Math.abs(num) * 100).toPrecision(15));
		return Math.round(m) / 100 * Math.sign(num);
	}

	async setTaxDefault(amount) {
		const tax = await this.db.getTax(3);
		return [{ id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: amount, tax_amount: 0 }];
	}

	async calculateTaxesInclude(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				//taxes.push();
				const tax = await this.db.getTax(id);
				console.log(id, tax)
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: amount };
				t.tax_amount = TaxesHooks.calculatedFromTaxInclude(tax, amount);
				taxes.push(t);
			}
		}
		return taxes;
	}
	async calculateTaxes(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				//taxes.push();
				const tax = await this.db.getTax(id);
				console.log(id, tax)
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: this.round(amount) };
				t.tax_amount = this.round(TaxesHooks.calculated(tax, amount));
				taxes.push(t);
			}
		}
		return taxes;
	}

	getCurrentOrder() {
		return this.currentOrder;
	}
	deleteItem(item) {
		this.getCurrentOrder().deleteItem(this, item);
		this.calculateDataTaxes();
		this.notifyChangeInOrder();
		this.setPriceTip(this.getCurrentOrder(), null);
	}

	deletePayment(item) {
		this.getCurrentOrder().deletePayment(this, item);
		this.notifyChangeInOrder();
	}

	deleteAllPayments() {
		this.getCurrentOrder().deleteAllPayments(this);
		this.notifyChangeInOrder();
	}

	printRemote(order, printAll) {
		order.printRemote(this.store, printAll, this.filters, this.db);
		setTimeout(() => {
			order.setDataItemsPrinted();
			this.notifyChangeInOrder();
		}, 500);
		
	}

	async printReceiptScreen(order) {
		//let printConfig = await this.getPrintConfig();
		let printConfig = await this.db.getPrintConfig();
		//console.log(printConfig)
		//let printer = this.getPrinterForPrint(printConfig, 'kitchen');

		order.printReceipt(this.store, true, this.filters, this.db, printConfig);
	}

	async printReceipt(order) {
		order.printReceipt(this.store, false, this.filters, this.db, null);
	}

	async printOrderServer(orderServer) {
		let order = this.loadOrderFromArray(orderServer);
		order.printReceipt(this.store, false, this.filters, this.db);
	}

	printTurn(turn) {
		let objTurn = new Turn();
		objTurn.printTurn(this.store, turn, this.filters, this.db);
	}

	printTurnReportFiscal(turn) {
		let objTurn = new Turn();
		objTurn.printFiscalReport(this.store, turn, this.filters, this.db);
	}

	async printTurnReportFiscalServer(turnReportServer) {
		await printManager.printFromFormat(turnReportServer, this.store, this.filters, this.db);
	}

	setCustomer(customer) {
		this.getCurrentOrder().setCustomer(customer);
		this.notifyChangeInOrder();
	}

	async assignConsecutiveOrder(order) {
		return new Promise(async (resolve, reject) => {
			let response = {
				success: false,
				msg: ''
			}

			try {

				let turn = this.store.getters.turn;
				//console.log(turn)
				//let cash_register = await this.db.getCashRegister(turn.cash_register);
				let cash_register = await this.db.getCashRegister(turn.bill_cash_register_id);
				/*let obj_consecutive = await this.db.getConsecutiveById(cash_register.bus_consecutive_id);
				if (!obj_consecutive) {
					response.success = false;
					response.msg = 'Esta caja no tiene consecutivo asignado, verifique en la configuración y sincronice';
					resolve(response);
				}*/

				const fechaActual = new Date();
				if (obj_consecutive.valid_until) {
					if (new Date(obj_consecutive.valid_until).getTime() < fechaActual.getTime()) {
						response.success = false;
						response.msg = 'El consecutivo venció el ' + obj_consecutive.valid_until + ', debe renovarlo!.';
						resolve(response);
					}
				}
				if (obj_consecutive.current_number == obj_consecutive.final_number) {
					response.success = false;
					response.msg = 'La numeración del consecutivo llegó a su valor máximo.';
					resolve(response);
				}

				/*let consecutive = this.calcConsecutive(obj_consecutive);

				console.log(consecutive)

				if (consecutive) {
					await this.db.updateConsecutiveCurrentNumber(obj_consecutive);
					order.setTurnLocalReference(turn.local_reference);
					order.setConsecutiveOrderText(consecutive.consecutive_order_text);
					order.setConsecutiveOrder(consecutive.number);
					order.setDataConsecutive(obj_consecutive);
					//console.log(order)

					response.success = true;
				}*/

				//this.notifyChangeInOrder();
				response.success = true;
				resolve(response);
			} catch (error) {
				response.success = false;
				console.log(error)
				response.msg = error.message;
				resolve(response);
			}
		})
	}

	async setConsecutive() {
		return new Promise(async (resolve, reject) => {
			/*let response = {
				success: false,
				msg: ''
			}

			try {
				if (!this.getCurrentOrder().getIsElectronicInvoice()) {
					if (!this.getCurrentOrder().getConsecutiveOrder()) {
						let turn = this.store.getters.turn;
						//console.log(turn)
						//let cash_register = await this.db.getCashRegister(turn.cash_register);
						let cash_register = await this.db.getCashRegister(turn.cash_register.id);
						let obj_consecutive = await this.db.getConsecutiveById(cash_register.bus_consecutive_id);
						if (!obj_consecutive) {
							response.success = false;
							response.msg = 'Esta caja no tiene consecutivo asignado, verifique en la configuración y sincronice';
							resolve(response);
						}

						const fechaActual = new Date();
						if (obj_consecutive.valid_until) {
							if (new Date(obj_consecutive.valid_until).getTime() < fechaActual.getTime()) {
								response.success = false;
								response.msg = 'El consecutivo venció el ' + obj_consecutive.valid_until + ', debe renovarlo!.';
								resolve(response);
							}
						}
						if (obj_consecutive.current_number == obj_consecutive.final_number) {
							response.success = false;
							response.msg = 'La numeración del consecutivo llegó a su valor máximo.';
							resolve(response);
						}

						let consecutive = this.calcConsecutive(obj_consecutive);

						let oldOrder = await this.db.getOrderByConsecutive(consecutive.consecutive_order_text);
						if (oldOrder) {
							response.success = false;
							response.msg = 'Ya existe el consecutivo ' + consecutive.consecutive_order_text + ', verifique en la configuración y sincronice';
							resolve(response);
						}

						if (consecutive) {
							this.getCurrentOrder().setTurnLocalReference(turn.local_reference);
							this.getCurrentOrder().setConsecutiveOrderText(consecutive.consecutive_order_text);
							this.getCurrentOrder().setConsecutiveOrder(consecutive.number);
							this.getCurrentOrder().setDataConsecutive(obj_consecutive);
							await this.db.updateConsecutiveCurrentNumber(obj_consecutive);
							response.success = true;
						}
					} else {
						response.success = true;
					}
				} else {
					response.success = true;
				}*/
			//this.notifyChangeInOrder();
			resolve({ success: true });
			/*} catch (error) {
				response.success = false;
				console.error(error)
				response.msg = error.message;
				resolve(response);
			}*/
		})
	}

	calcConsecutive(consecutive) {
		let string = '';
		let actual_number = '';
		if (consecutive) {
			if (consecutive.prefix) {
				string += consecutive.prefix;
			}
			if (consecutive.number_of_digits > 0) {
				string += this.strPad(consecutive.current_number + '', '0', consecutive.number_of_digits);
			} else {
				string += consecutive.current_number;
			}
			if (consecutive.suffix) {
				string += consecutive.suffix;
			}
			actual_number = consecutive.current_number;
			return { consecutive_order_text: string, number: actual_number }
		}
		return null;
	}

	strPad(text, padChar, size) {
		//console.log(text)
		return text.padStart(size, padChar);
	}

	setDomiciliary(person) {
		this.getCurrentOrder().setDomiciliary(person);
		this.notifyChangeInOrder();
	}

	setSeller(person) {
		this.getCurrentOrder().setSeller(person);
		this.notifyChangeInOrder();
	}

	totalizePaymentsFromOrders(orders) {
		let payments = {};
		for (const order of orders) {
			if (order.data_payments) {
				for (const pay of order.data_payments) {
					let value = parseFloat(pay.value);
					if (!value) {
						value = 0;
					}
					if (isNaN(value)) {
						value = 0;
					}
					let payment_method_id = pay.payment_method.id;
					if (!payments[payment_method_id]) {
						payments[payment_method_id] = {
							id: payment_method_id,
							name: pay.payment_method.name,
							system_value: value,
							counted_value: null
						};
					} else {
						payments[payment_method_id].system_value += value;
					}
				}
			}
		}
		return payments;
	}

	async updateItem(itemLine, pr, vr, qt, note, options, discount, new_price, price_list_id) {
		return new Promise(async (resolve, reject) => {

			if (options) {
				for (const i in options) {
					const opt = await this.db.getSaleOption(i);
					//console.log(opt)
					options[i].value = options[i].value;
					if (opt.option_type != 'custom') {
						options[i].value = options[i].value;
					}

					options[i].name = opt.name;
					options[i].option_type = opt.option_type;
					options[i].apply_price = opt.apply_price;
				}
			}
			//console.log(itemLine, pr, vr, qt, note, options, discount)
			const item = await this.getCurrentOrder().updateItem(this, itemLine, pr, vr, qt, note, options, discount, new_price, price_list_id);
			//console.log(item)
			for (const i in item.options) {
				const opt = item.options[i];
				if (opt.option_type != 'custom') {
					let values = []
					if (Array.isArray(opt.value)) {
						values = [...opt.value]
					} else {
						values = [opt.value]
					}
					//console.log(opt, values)
					for (const v of values) {
						if (v) {
							const product = await this.db.getProduct(v);

							let subitem = null;
							//console.log(opt.apply_price)
							if (opt.apply_price) {
								//await ItemLine.createItem(posManager, this.createReference(), product, variant, qt, note, options, discount, new_price, price_list_id);
								subitem = await ItemLine.createItem(this, this.createReference(), product, {}, 1, '', null, 0, -1, price_list_id);
							} else {
								subitem = await ItemLine.createItem(this, this.createReference(), product, {}, 1, '', null, 0, 0, price_list_id);
							}
							if (!item.sub_items) {
								item.sub_items = [];
							}
							item.sub_items.push(subitem);
						}
					}

				}
			}
			this.calculateDataTaxes(item);
			this.setPriceTip(this.getCurrentOrder(), null);
			this.notifyChangeInOrder();
			resolve(item);
		})
	}
	async addItem(pr, vr, qt, note, options, discount, new_price, price_list_id) {
		// console.log(pr, vr, qt, note, options, discount)
		// const opts = [];

		if (options) {
			for (const i in options) {
				const opt = await this.db.getSaleOption(i);
				options[i].value = options[i].value;
				if (opt.option_type != 'custom') {
					options[i].value = options[i].value;
				}

				options[i].name = opt.name;
				options[i].option_type = opt.option_type;
				options[i].apply_price = opt.apply_price;
			}
		}
		const item = await this.getCurrentOrder().addItem(this, pr, vr, qt, note, options, discount, new_price, price_list_id);

		for (const i in item.options) {
			const opt = item.options[i];
			if (opt.option_type != 'custom') {
				let values = []
				if (Array.isArray(opt.value)) {
					values = [...opt.value]
				} else {
					values = [opt.value]
				}
				for (const v of values) {

					const product = await this.db.getProduct(v);
					let subitem = null;
					if (opt.apply_price) {

						subitem = await ItemLine.createItem(this, this.createReference(), product, {}, 1, '', null, 0, -1, price_list_id);
					} else {
						subitem = await ItemLine.createItem(this, this.createReference(), product, {}, 1, '', null, 0, 0, price_list_id);
					}
					if (!item.sub_items) {
						item.sub_items = [];
					}
					item.sub_items.push(subitem);
				}
			}
		}
		this.calculateDataTaxes(item);
		this.setPriceTip(this.getCurrentOrder(), null);
		this.notifyChangeInOrder();
	}
	/* 
		Al momento de crear el item (item-line.js, linea 54), se ejecuta el metodo calculateTaxesInclude (en este archivo), para calcular las bases e impuestos menos los descuentos sin redondeos.
		Los redondeos se calculan en el metodo calculateTaxes (en este archivo), para obtener las bases e impuestos correctamente redondeadas.
	*/
	/*
	async calculateTaxesInclude(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				const tax = await this.db.getTax(id);
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: amount };
				t.tax_amount = TaxesHooks.calculatedFromTaxInclude(tax, amount);
				taxes.push(t);
			}
		}
		return taxes;
	}
	*/
	async calculateTaxesInclude(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				//taxes.push();
				const tax = await this.db.getTax(id);
				console.log(id, tax)
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: amount };
				t.tax_amount = TaxesHooks.calculatedFromTaxInclude(tax, amount);
				taxes.push(t);
			}
		}
		return taxes;
	}

	/* 
		Al momento de crear el item (item-line.js, linea 54), se ejecuta el metodo calculateTaxesInclude (en este archivo), para calcular las bases e impuestos menos los descuentos sin redondeos.
		Los redondeos se calculan en el metodo calculateTaxes (en este archivo), para obtener las bases e impuestos correctamente redondeadas.
	*/
	/*
	async calculateTaxes(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				const tax = await this.db.getTax(id);
				let taxable_amount_round = this.round(amount);
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: taxable_amount_round };
				t.tax_amount = this.round(TaxesHooks.calculated(tax, amount));
				taxes.push(t);
			}
		}
		return taxes;
	}
	*/
	async calculateTaxes(amount, taxesIds) {

		let taxes = null;
		if (taxesIds && taxesIds.length > 0) {
			taxes = [];
			for (const id of taxesIds) {
				//taxes.push();
				const tax = await this.db.getTax(id);
				console.log(id, tax)
				const t = { id: tax.id, name: tax.name, base: tax.base, type: tax.identifier, percentage: tax.percentage, taxable_amount: this.round(amount) };
				t.tax_amount = this.round(TaxesHooks.calculated(tax, amount));
				taxes.push(t);
			}
		}
		return taxes;
	}

	calculateDataTaxes() {
		let taxes = {};
		let totalTax = 0;
		let totalBase = 0;
		let totalBaseDiscount = 0;
		let currentOrder = this.getCurrentOrder();
		if (currentOrder.data_items.length > 0) {
			let itemsTmp = JSON.parse(JSON.stringify(currentOrder.data_items));

			let subItemsTmp = [];
			for (let item of itemsTmp) {
				if (item.sub_items && item.sub_items.length > 0) {
					subItemsTmp = [...subItemsTmp, ...item.sub_items];
				}
			}

			if (subItemsTmp.length > 0) {
				itemsTmp = [...itemsTmp, ...subItemsTmp];
			}

			for (const item of itemsTmp) {
				// let tmpTaxes = JSON.parse(JSON.stringify(item.taxes));
				for (let rowTx of item.taxes) {
					let valueTax = rowTx['tax_amount'];
					let valueBase = rowTx['taxable_amount'];
					if (taxes[rowTx['id']]) {
						taxes[rowTx['id']]['tax_amount'] += valueTax;
						taxes[rowTx['id']]['taxable_amount'] += valueBase;
					} else {
						taxes[rowTx['id']] = rowTx;
					}
					totalTax += parseFloat(valueTax);
					// totalBase += parseFloat(valueBase);
				}

				totalBase += parseFloat(item.line_extension_amount);
				totalBaseDiscount += parseFloat(item.subtotal_amount);
			}
			this.setDataTaxes(this.getCurrentOrder(), taxes);
			this.setTotalTaxes(this.getCurrentOrder(), totalTax);
			this.setTotalBase(this.getCurrentOrder(), totalBase);
			this.setSubtotal(this.getCurrentOrder(), totalBaseDiscount);
			this.setTotalDiscounts(this.getCurrentOrder(), this.getCurrentOrder().getTotalDiscount());
		}
	}

	hasOffer(product) {
		const { priceList, priceListGeneral } = this.getPriceListForProduct(product);

		if (priceList) {
			if (product.prices[priceList.id]) {
				if (parseFloat(product.prices[priceList.id].price_offer) >= 0) {
					return true;
				}
			}
		}
		return false;
	}
	// retorna el precio comercial sin impuestos
	getPrice(product) {
		const { priceList, priceListGeneral } = this.getPriceListForProduct(product);
		if (priceList) {
			if (product.prices[priceList.id] && product.prices[priceList.id].price) {
				return product.prices[priceList.id].price;
			} else {
				return product.prices[priceListGeneral.id].price;
			}
		} else {
			return product.prices[priceListGeneral.id].price;
		}
		return '';
	}
	// retorna el precio de oferta sin impuestos
	getPriceOffer(product) {
		const { priceList, priceListGeneral } = this.getPriceListForProduct(product);
		if (priceList) {
			//console.log(product.prices[priceList.id].sale_price, product.prices[priceListGeneral.id].sale_price)
			if (product.prices[priceList.id] && product.prices[priceList.id].price_offer) {
				return parseFloat(product.prices[priceList.id].price_offer);
			} else {
				return parseFloat(product.prices[priceListGeneral.id].price_offer);
			}
		} else {
			return parseFloat(product.prices[priceListGeneral.id].price_offer);
		}
		return '';
	}

	calculateDiscount(price, discount) {
		return /*(price -*/ (price * discount / 100);
	}

	getSalePriceProduct(product, discount, lp){
		const { priceList, priceListGeneral } = this.getPriceListForProduct(product, lp);
		if (priceList) {
			if (!discount) {
				//console.log(product, product.prices[priceList.id], discount)
				if (product.prices && product.prices[priceList.id] && product.prices[priceList.id].sale_price) {
					return parseFloat(product.prices[priceList.id].sale_price);
				} else {
					return 0;
				}
			} else {

				return this.calculateSalePrice(product, product.prices[priceList.id], discount);
			}
		} else {
			return parseFloat(product.prices[priceListGeneral.id].sale_price);
		}
		return '';
	}
	// el servidor retorna el precio de venta y de oferta con los impuestos calculados
	getSalePrice(product, discount, lp, variantId) {
		//console.log(product.name, variantId, '...getSalePrice')
		const { priceList, priceListGeneral } = this.getPriceListForProduct(product, lp);
		//console.log(product.prices, priceList.id,  lp)
		if (!variantId) {
			return this.getSalePriceProduct(product, discount, lp);
		} else {
			if (priceList) {
				if (!discount) {
					//console.log(product, product.prices[priceList.id], discount)
					if (product.variant_prices && product.variant_prices[priceList.id] && product.variant_prices[priceList.id][variantId] && product.variant_prices[priceList.id][variantId].sale_price) {
						return parseFloat(product.variant_prices[priceList.id][variantId].sale_price);
					} else {
						return this.getSalePriceProduct(product, discount, lp);
					}
				} else {
					if(product.variant_prices && product.variant_prices[priceList.id] && product.variant_prices[priceList.id][variantId]){
						return this.calculateSalePrice(product, product.variant_prices[priceList.id][variantId], discount);
					}else{
						return this.getSalePriceProduct(product, discount, lp);
					}
					
				}
			} else {
				if(product.variant_prices && product.variant_prices[priceList.id] && product.variant_prices[priceList.id][variantId]){
					return parseFloat(product.variant_prices[priceListGeneral.id][variantId].sale_price);
				}else{
					return this.getSalePriceProduct(product, discount, lp);
				}
			}
		}
		return '';
	}


	calculateSalePrice(product, priceProduct, discount) {
		const priceItem = parseFloat(priceProduct.price);
		const offerItem = parseFloat(priceProduct.price_offer);
		let price = 0;
		if (offerItem > 0 && priceItem > offerItem) {
			price = offerItem;
		} else {
			price = priceItem;
		}
		if (discount) {
			price = price - this.calculateDiscount(price, discount);
		}
		return price;
	}



	getPriceListForProduct(product, lpId) {
		const client = this.getCurrentOrder().getCustomer();
		/*if(!this.priceLists){
			await this.loadPriceLists();
		}*/
		let lp = null;
		let lpGeneral = null;
		//console.log(this.priceLists)
		if (lpId) {
			for (const priceList of this.priceLists) {
				if (lpId == priceList.id) {
					lp = priceList;
				}
				if (priceList.is_general) {
					lpGeneral = priceList;
				}
			}

			return { priceList: lp, priceListGeneral: lpGeneral }
		}
		lp = this.priceLists[0];
		lpGeneral = this.priceLists[0];
		for (const item of this.priceLists) {
			if (item.type && item.type == 'pos') {
				lp = item;
			}
			if (item.is_general) {
				lpGeneral = item;
			}
		}
		if (client) {
			// verificar si tiene un tipo de cliente especifico para buscar la lista correspondiente
			//lp = lista de acuerdo al tipo;
		}

		return { priceList: lp, priceListGeneral: lpGeneral }
	}

	setCurrentOrder(order) {
		this.currentOrder = this.loadOrderFromArray(order);
		this.notifyChangeInOrder();
	}

	loadOrderFromArray(item) {
		let order = new Order();
		for (const key in item) {
			if (Object.hasOwnProperty.call(item, key)) {
				order[key] = item[key];
			}
		}
		return order;
	}

	async syncOrder(order) {
		//order.date_sync = null;
		const localOrder = await this.db.saveOrder(order, this.http);
		if (this.store.getters.online) {
			let paramsRequest = '';
			if (order.id) {
				paramsRequest = '/' + order.id;
			}
			if (!order.order_status) {
				order.order_status = 'created';
			}
			this.http.post('api/v1/order' + paramsRequest, order, false, false).then(async (response) => {
				if (response.success) {
					// la respuesta del servidor debe traer la fecha de syncronización
					if (response.item) {
						order.is_sync = true;
						order.date_sync = response.item.updated_at;
						await this.db.saveOrder(order, this.http);
					}
				} else {

				}
			}).catch(err => {
				console.log(err)
			});
		}
	}

	// actualiza informacion de productos y demas en la bd local desde el server
	syncroRemoteToLocal() {
		return new Promise(async (resolve, reject) => {
			const installObj = await this.db.getInstallation();

			//const data = { bus_subsidiary_id: installObj.subsidiary_id, bill_cash_register_id: installObj.cash_id, type: 'pos'  }
			this.http.get('api/v1/subsidiary/pos-get-info/' + installObj.subsidiary_id, null, true, false).then(async (response) => {
				if (response.success) {
					this.store.commit('setLoading', true);
					this.db.deleteAll('employees');
					this.db.deleteAll('payment_methods');
					this.db.deleteAll('products');
					this.db.deleteAll('product_variants');
					this.db.deleteAll('sale_options');
					this.db.deleteAll('templates');
					console.log('delete items...')
					for (const i in response.config) {
						console.log('updating...', i)
						await this.db.updateItems(i, response.config[i]);
					}
					this.store.commit('setLoading', false);
					resolve(true);
				} else {
					resolve(false);
				}
			});
		})
	}


	async cleanLocalStorage() {
		this.store.commit('setLoading', true);
		try {
			let days = 6;
			if (this.store.getters.getPosValue('pos_days_of_clean_local_storage')) {
				days = this.store.getters.getPosValue('pos_days_of_clean_local_storage');
				if (isNaN(days)) {
					days = 3;
				}
			}
			let now = new Date();
			now.setHours(0);
			now.setMinutes(0);
			now.setSeconds(0);
			now.setDate(now.getDate() - days);
			await this.db.cleanLocalOrders(now.toISOString());
			await this.db.cleanLocalTurns(now.toISOString());
			setTimeout(() => {
				this.store.commit('setLoading', false);
				this.store.commit('setMessage', 'La Limpieza se realizo correctamente.');
			}, 1000);

		} catch (error) {
			setTimeout(() => {
				this.store.commit('setLoading', false);
				this.store.commit('setError', error.message);
			}, 1000);
		}
	}

	async emptyTables() {
		/*
		this.store.commit('setLoading', true);
		const tables = await this.db.getTables();
		if (tables.length > 0) {
			for (const t of tables) {
				let table = await this.db.getTableByName(t.name);
				await this.db.updateTableBusy(table, false);
			}
		}
		setTimeout(() => {
			this.store.commit('setLoading', false);
			this.store.commit('setMessage', 'Mesas reiniciadas correctamente.');
		}, 1000);*/
	}

	async sincronizeSync() {
		this.store.commit('setLoading', true);
		try {
			await this.sincronizeTurnosSync();
			await this.sincronizeOrdenesSync();
			//setTimeout(() => {
			this.store.commit('setLoading', false);
			//this.store.commit('setMessage', 'La sincronización hacia la nube se realizo correctamente.');
			//}, 1000);

		} catch (error) {
			setTimeout(() => {
				//this.store.commit('setLoading', false);
				this.store.commit('setError', error.message);
			}, 1000);
		}
	}


	async sincronizeTurnosSync() {
		if (this.store.getters.online) {
			const turns = await this.db.getTurnsNotSyncro();
			console.log(turns)
			if (turns.length > 0) {
				for (const t of turns) {
					//console.log(t);
					this.syncTurn(t);
				}

			}
		}
	}

	async sincronizeOrdenesSync() {
		if (this.store.getters.online) {
			const orders = await this.db.getOrdersNotSyncro();
			console.log(orders);
			if (orders.length > 0) {
				for (const order of orders) {
					//this.syncOrder(order);
					this.saveOrder(order, this.http);
				}
			}
		}
		/*
		const online = await this.plt.getConectionStatus().value;
		if(online){    
		  if (this.sessionService.getPersonaId()) {
			const resultOrdenes: any = await Orden.getOrdenesNoSincronizadas();
			if (resultOrdenes.length > 0) {
			  // alert('Existen ' + resultOrdenes.length + ' ordenes para sincronizar.');
			  console.log(resultOrdenes.length + 'ordenes para sincronizar.');
			  for (const order of resultOrdenes) {
				const orden = new Orden();
				orden.fromArray(order);
				console.log('sincronizando orden:', orden);
				const response: any = await orden.saveAndSyncro(
				  this.restService,
				  'pos/orden/create'
				);
				if (response.code === 'NOT_ENOUGH_STOCK') {
				  alert(
					// tslint:disable-next-line:max-line-length
					'No existen unidades disponibles en el sistema, por favor revise y actualice el nivel de inventario para poder sincronizar las ordenes.'
				  );
				  return;
				}
				if (response.success) {
				  if (response.item.pagos) {
					orden.setPagosSyncro(response.item.pagos);
				  }
				  if (response.item.sincronizado) {
					orden.setSincronizado(response.item.sincronizado);
				  }
				  if (response.item.fecha_sincronizacion) {
					orden.setFechaSincronizacion(
					  response.item.fecha_sincronizacion
					);
				  }
				  if (response.item.cliente_data) {
					orden.cliente_data = response.item.cliente_data;
					orden.fact_cliente_id = response.item.cliente_data.id;
					this.storageService.saveClient(response.item.cliente_data);
				  }              
				  orden.save();
				}
			  }
			} else {
			  console.log('No hay ordenes para sincronizar');
			}
		  }
		}*/


	}


}


export default PosManager;

