
import utilReceiptPOS from '../util/util-receipt-pos'; 
import printManager from '../print-manager';

class Turn {
	constructor() {
		
	}

	async printTurn(store, turn, filters, db){
		console.log(turn);
		let fontSize = '15';
		let installation = store.getters.installation;		
		if (installation) {
			const cashRegister = await db.getCashRegister(
			  installation.cash_id
			);
			if(cashRegister){
				if(cashRegister.config){
					if(cashRegister.config.pos_printer_font_size){
						fontSize = cashRegister.config.pos_printer_font_size;
					}
				}
			}
		}
		const receipt = new utilReceiptPOS(store, fontSize, filters, db);	
		await receipt.setDataCompany();
		receipt.setTitle("Cuadre de Turno");
		if(turn.cash_register && turn.cash_register.name){
			receipt.setReference(turn.cash_register.name);
		}

		/*if(turn.user_created_id ){
			let employee= await db.getEmployeeById(turn.user_created_id);
			if(employee){
				receipt.addHeaderText("Abierto por: " + employee.name);
			}
		}*/
		if(turn.opening_date){
			receipt.setTurnOpeningDate(filters.localdatetime(turn.opening_date));			
		}
		if(turn.closing_date){
			receipt.setTurnClosingDate(filters.localdatetime(turn.closing_date));			
		}
		
		await receipt.addHeader('turn');
		let tableItems = [];
		if(turn.data_payments){
			for (const row of turn.data_payments) {
				let item = [];
				item.push(row['name'] + ' Sistema: ' + filters.currency(row['system_value'], 0));
				item.push(row['name'] + ' Registrado:' + filters.currency(row['counted_value'], 0) );			
				tableItems.push( item );				
			}
			receipt.addTable( tableItems, false, (fontSize - 1));
		}

		receipt.pushTotal('Monto Inicial', turn.starting_amount , fontSize);
		receipt.pushTotal('Total Ventas', turn.total_sales, fontSize);
		receipt.pushTotal('Monto Registrado', turn.final_amount, fontSize);
		receipt.pushTotal('Diferencia', turn.difference, fontSize);
		receipt.pushTotal('Total Domicilios', turn.delivery_amount, fontSize);
		receipt.pushTotal('Total Propinas', turn.tip_amount, fontSize);		
		receipt.addTotals();	
		
		

		if(turn.observation){
			receipt.addText(turn.observation, 'justify', (fontSize ));
		}
		receipt.addFooter();		
		printManager.printFromFormat(receipt.render(), store, filters, db); 		
	}

	async printFiscalReport(store, turn, filters, db){	
		
		
		
		let fontSize = '15';
		let installation = store.getters.installation;		
		if (installation) {
			const cashRegister = await db.getCashRegister(
			  installation.cash_id
			);
			if(cashRegister){
				if(cashRegister.config){
					if(cashRegister.config.pos_printer_font_size){
						fontSize = cashRegister.config.pos_printer_font_size;
					}
				}
			}
		}
		const receipt = new utilReceiptPOS(store, fontSize, filters, db);	
		await receipt.setDataCompany();
		receipt.setTitle("Reporte Fiscal");
		
		if(turn.cash_register && turn.cash_register.name){
			receipt.setReference(turn.cash_register.name);
		}

		if(turn.user_created_id ){
			let employee= await db.getEmployeeById(turn.user_created_id);
			if(employee){
				receipt.addHeaderText("Abierto por: " + employee.name);
			}
		}
		if(turn.opening_date){
			receipt.setTurnOpeningDate(filters.localdatetime(turn.opening_date));			
		}
		if(turn.closing_date){
			receipt.setTurnClosingDate(filters.localdatetime(turn.closing_date));			
		}else{
			receipt.setTurnClosingDate('N/A');
		}
		await receipt.addHeader('turn');

		const items = await this.calculateFiscalReportOffline(store, turn, filters, db);
		if(items && items.length > 0){
			for (const row of items) {
				receipt.addText(row.label + ": " + row.value, 'left', (fontSize ));
			}			
		} else {
			receipt.addText("No hay ventas en el turno actual", 'justify', (fontSize ));
		}
		
		/*
		
		let tableItems = [];
		if(turn.data_payments){
			for (const row of turn.data_payments) {
				let item = [];
				item.push(row['name'] + ' Sistema: ' + filters.currency(row['system_value'], 0));
				item.push(row['name'] + ' Registrado:' + filters.currency(row['counted_value'], 0) );			
				tableItems.push( item );				
			}
			receipt.addTable( tableItems, false, (fontSize - 1));
		}

		receipt.pushTotal('Monto Inicial', turn.starting_amount , fontSize);
		receipt.pushTotal('Total Ventas', turn.total_sales, fontSize);
		receipt.pushTotal('Monto Registrado', turn.final_amount, fontSize);
		receipt.pushTotal('Diferencia', turn.difference, fontSize);
		receipt.pushTotal('Total Domicilios', turn.delivery_amount, fontSize);
		receipt.pushTotal('Total Propinas', turn.tip_amount, fontSize);
		
		receipt.addTotals();
		if(turn.observation){
			receipt.addText(turn.observation, 'justify', (fontSize ));
		}*/
		receipt.addFooter();		
		printManager.printFromFormat(receipt.render(), store, filters, db); 		
	}

	async calculateFiscalReportOffline(store, turn, filters, db) {
		let items = []
		let orders = [];
		orders = await db.getOrdersByTurnOrderConsecutive(turn);
		if(orders && orders.length > 0){
			const firstOrder = orders[0];
			const lastOrder = orders[orders.length - 1];
			const totalOrders = await this.calculateTotalsOrders(orders);
			// console.log(firstOrder);
			items.push({'label': 'Consecutivo Inicial', 'value': firstOrder.consecutive_order_text});
			items.push({'label': 'Consecutivo Final', 'value': lastOrder.consecutive_order_text});
			items.push({'label': 'Cantidad de transacciones', 'value': orders.length});
			items.push({'label': 'Valor total transacciones', 'value': totalOrders.total_sales});
			

			// items = orders;			
		}
		return items;
	}

	async calculateTotalsOrders(orders) {
		let total = {
			total_sales: 0,
			total_taxes: 0
		}
		for (const order of orders) {
			console.log(order);
		  if(!isNaN(order.total_factura)){
			if(!isNaN(order.total_impuestos)){
			  
			}        
		  }
		  if(!isNaN(order.valor_propina)){
			// this.totalPropinas += order.valor_propina;
		  }
		}
		return total;
	  }

}

export default Turn;